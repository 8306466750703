const pricing = {
  standard: {
    3: 7.5,
    4: 10.0,
    5: 12.5,
    6: 15.0,
  },
  morning: {
    3: 3.75,
    4: 5.0,
    5: 6.25,
    6: 7.5,
  },
};

module.exports = pricing;
