<template>
  <div class="selectors">
    <h2>Rezervēt laiku:</h2>
    <form @submit.prevent="createAppointment()">
      <input
        v-model="name"
        type="text"
        name="name"
        placeholder="Vārds, uzvārds"
        required
      >
      <input
        v-model="phone"
        type="tel"
        name="phone"
        placeholder="Telefona numurs"
        required
      >
      <select
        v-model="dateIndex"
        @change="time = Object.keys(availableTimeSlots[duration][dateIndex])[0]"
      >
        <option
          v-for="(d, index) in availableDates"
          :key="index"
          :value="index"
        >
          {{ availableDates[index].date_short + '  -  ' + availableDates[index].day_text }}
        </option>
      </select>
      <select v-model="duration">
        <option
          v-for="(value, key) in durations"
          :key="key"
          :value="key"
        >
          {{ value }}
        </option>
      </select>

      <select
        v-if="currentTime"
        v-model="time"
      >
        <option
          v-for="(value, key) in availableTimeSlots[duration][dateIndex]"
          :key="key"
          :value="key"
        >
          {{ value }}
        </option>
      </select>
      <select
        v-else
        disabled
      >
        <option
          :value="-1"
          selected
        >
          Visi laiki ir aizņemti
        </option>
      </select>

      <label class="error-message">{{ errorMessage }}</label>
      <input
        class="btn"
        type="submit"
        value="Pieteikt rezervāciju"
      >
    </form>
  </div>
</template>

<script>
export default {
  name: 'InputForm',
  components: { },
  data() {
    return {
      errorMessage: '',
      date: '',
      config: {
        defaultDate: 'today',
      },
      name: '',
      phone: '',
      dateIndex: 0,
      duration: 3,
      prevDuration: 3,
      time: 0,
      timeSlots: {
        0: '06:30',
        1: '06:45',
        2: '07:00',
        3: '07:15',
        4: '07:30',
        5: '07:45',
        6: '08:00',
        7: '08:15',
        8: '08:30',
        9: '08:45',
        10: '09:00',
        11: '09:15',
        12: '09:30',
        13: '09:45',
        14: '10:00',
        15: '10:15',
        16: '10:30',
        17: '10:45',
        18: '11:00',
        19: '11:15',
        20: '11:30',
        21: '11:45',
        22: '12:00',
        23: '12:15',
        24: '12:30',
        25: '12:45',
        26: '13:00',
        27: '13:15',
        28: '13:30',
        29: '13:45',
        30: '14:00',
        31: '14:15',
        32: '14:30',
        33: '14:45',
        34: '15:00',
        35: '15:15',
        36: '15:30',
        37: '15:45',
        38: '16:00',
        39: '16:15',
        40: '16:30',
        41: '16:45',
        42: '17:00',
        43: '17:15',
        44: '17:30',
        45: '17:45',
        46: '18:00',
        47: '18:15',
        48: '18:30',
        49: '18:45',
        50: '19:00',
        51: '19:15',
        52: '19:30',
        53: '19:45',
        54: '20:00',
        55: '20:15',
        56: '20:30',
        57: '20:45',
        58: '21:00',
        59: '21:15',
        60: '21:30',
        61: '21:45',
        62: '22:00',
        63: '22:15',
        64: '22:30',
        65: '22:45',
        66: '23:00',
        67: '23:15',
        68: '23:30',
        69: '23:45',
        70: '00:00',
      },
      durations: {
        3: '45 min',
        4: '60 min',
        5: '75 min',
        6: '90 min',
      },
      dayDict: {
        Monday: 'Pirmdien',
        Tuesday: 'Otrdien',
        Wednesday: 'Trešdien',
        Thursday: 'Ceturtdien',
        Friday: 'Piektdien',
        Saturday: 'Sestdien',
        Sunday: 'Svētdien',
      },
    };
  },
  computed: {
    availableDates() {
      const today = new Date();
      const next14Days = [];

      for (let i = 0; i < 14; i += 1) {
        const currentDate = new Date(today);
        currentDate.setDate(today.getDate() + i);

        const dateKey = currentDate.toLocaleString('en-US', { weekday: 'long' });
        const dateValue = currentDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const [month, day, year] = dateValue.split('/');
        const dateVal = parseInt(`${year}${month}${day}`, 10);
        const dateLong = `${year}-${month}-${day}`;
        const europeanDateKey = `${day}.${month}`;

        next14Days.push({
          day_text: this.dayDict[dateKey],
          date_short: europeanDateKey,
          date: dateLong,
          value: dateVal,
        });
      }
      return next14Days;
    },
    currentTime() {
      return this.currentDaySlots[0];
    },

    availableTimeSlots() {
      const availability = {
        3: [],
        4: [],
        5: [],
        6: [],
      };
      for (let duration = 3; duration < 7; duration += 1) {
        for (let i = 0; i < 14; i += 1) {
          availability[duration].push({ ...this.timeSlots });
          delete availability[duration][i][70];
        }

        for (let i = 0; i < 14; i += 1) {
          this.$store.getters.availability[i].ranges.forEach((appointment) => {
            if (this.availableDates[i].date
            === this.$store.getters.availability[i].date.slice(0, 10)) {
              const startKey = appointment.start.index;
              const endKey = appointment.end.index;

              for (let j = startKey; j < endKey; j += 1) {
                delete availability[duration][i][j];
              }
            }
          });

          this.$store.getters.cart.forEach((appointment) => {
            if (this.availableDates[i].date === appointment.date) {
              const startKey = appointment.start_index;
              const endKey = appointment.end_index;

              for (let j = startKey; j < endKey; j += 1) {
                delete availability[duration][i][j];
              }
            }
          });
        }

        const now = new Date();
        const currentHours = now.getHours();
        const currentMinutes = now.getMinutes();
        const totalMinutes = (currentHours * 60 + currentMinutes) - 390;
        const nowIndex = Math.floor(totalMinutes / 15) + 1;

        for (let i = 0; i < nowIndex; i += 1) {
          delete availability[duration][0][i];
        }

        for (let i = 0; i < 14; i += 1) {
          Object.keys(availability[duration][i]).forEach((key) => {
            for (let k = 1; k < this.duration; k += 1) {
              if (!availability[duration][i][parseInt(key, 10) + parseInt(k, 10)]) {
                delete availability[duration][i][key];
                break;
              }
            }
          });
        }
      }

      return availability;
    },
    currentDaySlots() {
      return Object.keys(this.availableTimeSlots[this.duration][this.dateIndex]);
    },
  },
  watch: {
    currentTime(val) {
      // console.log('currentTime:', val)
      this.time = val;
    },
    // availableTimeSlots(val){
    //   console.log('availableTimeSlots:', val)
    // },
    duration(newDuration, old) {
      // console.log('duration:', newDuration)
      this.prevDuration = old;
    },
    // dateIndex(val){
    //   console.log('dateIndex:', val)
    // },
    // currentDaySlots(val){
    //   console.log('currentDaySlots:', val)
    // }
  },
  mounted() {
    if (this.availableDates.length > 0) {
      this.dateIndex = 0;
    }

    this.time = this.currentTime;
  },
  methods: {
    createAppointment() {
      if (Object.keys(this.availableTimeSlots[this.duration][this.dateIndex]).length < 1) {
        this.time = -1;
      }

      if (this.name.length < 8 || this.phone.length < 8) {
        this.errorMessage = 'Lūdzu, aizpildi visus laukus!';
      }

      if (this.time === -1) {
        this.errorMessage = 'Lūdzu, izvēlies laiku!';
      } else {
        this.errorMessage = '';

        const appointmentDoc = {
          name: this.name,
          phone: this.phone,
          date: this.availableDates[this.dateIndex].date,
          start_index: parseInt(this.time, 10),
          end_index: parseInt(this.time, 10) + parseInt(this.duration, 10),
        };

        this.$store.dispatch('addToCart', appointmentDoc);

        this.duration = 3;
        [this.time] = Object.keys(this.availableTimeSlots[this.duration][this.dateIndex]);
      }
    },
    getDate(date) {
      const dateStr = date.toString();
      const day = dateStr.substring(6);
      const month = dateStr.substring(4, 6);
      const year = dateStr.substring(0, 4);
      return `${day}.${month}.${year}`;
    },
  },

};
</script>

  <style scoped lang="scss">
    @import 'colors.scss';
    .container{
      width: 400px;
    }

    .selectors{
      background-color: $color4;
      color: $color1;
      padding: 10px 5px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      margin-top: 60px;
    }
    .selectors form{
      display:flex;
      flex-direction: column;
      align-items: center;

      > *{
        margin-bottom: 24px;
      }

      .error-message{
        margin: unset;
        height: 40px;
        color:#810c0c;
      }
    }

    input{
      font-family: 'Poppins', 'sans-serif';
      width: 360px;
      color: $color1;
      background-color: $color4;
      border: unset;
      border-bottom: 1px solid $color1;
      padding: 10px;
      font-size: 18px;

      &::placeholder{
        color: $color1;
      }
    }

    h2{
      margin-bottom: 30px;
    }

    select{
      font-family: 'Poppins', 'sans-serif';
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 380px;
      color: $color1;
      background-color: $color4;
      border: unset;
      border-bottom: 1px solid $color1;
      padding: 10px;
      font-size: 18px;
      cursor: pointer;

      &::placeholder{
        color:$color3;
      }
    }

    .btn{
      background-color: $color4;
      color: $color1;
      border: 1px solid $color1;
      padding: 10px;
      width: 300px;
      font-family: 'Poppins', sans-serif;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }

    h2{
        font-family: 'Poppins', sans-serif;
    }

  </style>
