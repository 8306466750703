import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';

const store = createStore({
  state() {
    return {
      loadingAvailability: true,
      availability: [],
      cart: [],
    };
  },
  mutations: {
    setLoadingAvailability(state, status) {
      state.loadingAvailability = status;
    },
    setAvailability(state, availability) {
      state.availability = availability;
    },
    updateCart(state, item) {
      state.cart.push(item);
    },
    removeItem(state, index) {
      state.cart.splice(index, 1);
    },
  },
  actions: {
    fetchAvailability(context) {
      context.commit('setLoadingAvailability', true);

      fetch(`${process.env.VUE_APP_HOST_URL}/api/availability/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          context.commit('setAvailability', data);
          context.commit('setLoadingAvailability', false);
        })
        .catch((error) => {
          console.error('Error fetching availability:', error);
          context.commit('setLoadingAvailability', false);
        });
    },
    addToCart(context, item) {
      context.commit('updateCart', item);
    },
    removeFromCart(context, index) {
      context.commit('removeItem', index);
    },
    toggleLoading(context, status) {
      context.commit('setLoadingStatus', status);
    },
  },
  getters: {
    loadingStatus(state) {
      return state.loadingAvailability;
    },
    cart(state) {
      return state.cart;
    },
    availability(state) {
      return state.availability;
    },
  },
});

createApp(App).use(router).use(store).mount('#app');
