import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import SuccessView from '../views/SuccessView.vue';
import CancellationView from '../views/CancellationView.vue';
import ContactView from '../views/ContactView.vue';
import FourOhFour from '../views/FourOhFour.vue';
import TermsView from '@/views/TermsView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView,
  },
  {
    path: '/cancellation',
    name: 'cancellation',
    component: CancellationView,
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView,
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'four_oh_four',
    component: FourOhFour,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
