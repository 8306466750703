<template>
  <div class="spinner">
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LoadingSpinner',
};
</script>
<style scoped lang="scss">
    @import 'colors.scss';
    .spinner{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
    }

    .lds-ellipsis {
        display: inline-block;
        position: relative;
        width: 28px;
        height: 28px;
    }
    .lds-ellipsis div {
        position: absolute;
        top: 11px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $color1;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
        left: 3px;
        animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
        left: 3px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
        left: 11px;
        animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
        left: 20px;
        animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
        }
        @keyframes lds-ellipsis3 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
        }
        @keyframes lds-ellipsis2 {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(12px, 0);
        }
    }

</style>
