<template>
  <div
    class="success"
    :class="{hidden:!status}"
  >
    <LoadingSpinner v-if="!status" />
    <div
      v-else
      class="card"
    >
      <router-link to="/">
        <h1 class="title">
          skultes <br><span>gym</span>
        </h1>
      </router-link>
      <div v-if="status === 'paid'">
        <img :src="`http://api.qrserver.com/v1/create-qr-code/?data=${url}/success?session_id=${id}&size=150x150&color=30114a`">
        <h4 class="reserved-title">
          Rezervētie laiki:
        </h4>
        <div
          v-if="data.length"
          class="cart"
        >
          <div
            v-for="(item, index) of data"
            :key="index"
            class="cart__item"
          >
            <div class="cart__item__desc">
              <span class="cart__item__desc__date">
                {{ item.date }}
              </span>
              <span class="cart__item__desc__time">
                {{ item.start + " - " + item.end }}
              </span>
            </div>
            <a
              :href="item.add_to_calendar"
              target="_blank"
            >Pievienot Kalendāram</a>
            <!-- :to="`/cancel&appointment_id=${item.id}`" -->
            <button @click="createPopup(item.id)">
              Atcelt
            </button>
          </div>
        </div>
        <div v-else>
          Visas rezervācijas šajā pirkumā ir atceltas
        </div>
        <p class="disclaimer">
          Atceļot ne vēlāk kā 24h pirms rezervācijas, iegūsiet kuponu apmeklējuma vērtībā
        </p>
      </div>
      <div v-else-if="status === 'unknown'">
        <p class="thank-you">
          Kļūda!
        </p>
        <p>
          Rezervācija nav atrasta
        </p>
      </div>
      <div v-else-if="status === 'unpaid' || status === 'no_payment_required'">
        <p class="thank-you">
          Lūdzu, uzgaidiet!
        </p>
        <p>
          Rezervācija tiek apstrādāta
        </p>
      </div>
    </div>

    <div
      v-if="showPopup"
      class="popup"
    >
      <div class="popup-content">
        <p>Vai tiešām vēlaties atcelt šo rezervāciju?</p>
        <button @click="cancelAppointment">
          Jā
        </button>
        <button @click="closePopup">
          Nē
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from '../components/LoadingSpinner.vue';

export default {
  name: 'SuccessView',
  components: {
    LoadingSpinner,
  },
  data() {
    return {
      status: null,
      data: null,
      id: null,
      url: process.env.VUE_APP_SELF_URL,
      showPopup: false,
      currentAppointmentId: null,
    };
  },
  mounted() {
    if (this.$route.query.session_id) {
      this.id = this.$route.query.session_id;
      fetch(`${process.env.VUE_APP_HOST_URL}/api/checkout/${this.$route.query.session_id}`)
        .then((response) => {
          if (response.ok) return response.json();
          return response.json().then((json) => Promise.reject(json));
        })
        .then((data) => {
          console.log(data);
          this.status = data.payment_status;
          this.data = data.payment_data;
        })
        .catch((e) => console.error(e.error));
    } else {
      this.status = 'unknown';
    }
  },
  methods: {
    createPopup(id) {
      this.currentAppointmentId = id;
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.currentAppointmentId = null;
    },
    cancelAppointment() {
      this.$router.push(`/cancellation?id=${this.currentAppointmentId}`);
      this.closePopup();
    },
  },
};
</script>

<style scoped lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap');
  @import '../components/colors';
  @import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  .success{
    font-family: 'Poppins', 'sans-serif';

    &.hidden{
      padding-top: 20vh;
    }

    a{
      text-decoration: none;
      color: unset;
    }
  }

  .card{
    background-color: $color4;
    color: $color1;
    padding-top: 30px;

    .title{
      font-family: 'Jost', sans-serif;
      text-transform: uppercase;
      font-size: 40px;
      line-height: 30px;
      font-style: italic;
      font-weight: 700;
      margin: 0 auto;
      width: 400px;

      span{
        font-size: 40px;
        line-height: 28px;
      }
    }

    img{
      margin: 100px 40px;
      height: 150px;
    }

    .thank-you{
      margin-top: 0;
      margin-bottom: 0;
      font-size: 40px;
    }

    a{
      color: $color1;
    }

    .reserved-title{
      font-size: 22px;
      margin: 0px 5px 0px 5px;
      padding: 5px;
    }

    .disclaimer{
      padding: 5px 0;
      margin: 0;
      margin-top: 20px;
      font-size: 14px;
    }

    .cart{
        font-family: 'Poppins', sans-serif;
        color: $color1;
        font-size: 18px;
        background-color: $color4;
        padding: 10px 5px 0 5px;

        &__item{
            margin: 3px 2px 3px 2px;
            justify-content: space-between;
            align-items: center;
            padding: 3px 10px;
            border-bottom: 1px solid $color1;
            display: flex;

            a{
              font-size: 14px;
              border-left: 1px solid $color1;
              height: 50px;
              line-height: 50px;
              flex-grow: 1;
              text-align: center;
            }

            button{
              font-family: 'Poppins', 'sans-serif';
              background-color: transparent;
              color: $color1;
              border: none;
              cursor: pointer;
              font-size: 14px;
              border-left: 1px solid $color1;
              height: 50px;
              line-height: 50px;
              flex-grow: 1;
              text-align: center;
            }

            &:first-child{
              border-top: 1px solid $color1;
            }

            &__desc{
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                &__date{
                    font-weight: bold;
                }

                &__time{
                    font-size: 14px;
                }
            }
        }
      }

      .btn{
        font-size: 20px;
        margin-bottom: 24px;
        background-color: $color4;
        transition: background-color 1s ease;
        color: $color1;
        border: 1px solid $color1;
        padding: 10px;
        height: 52px;
        width: 300px;
        font-family: 'Poppins', sans-serif;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        text-decoration: none;
      }
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    position: relative;

    p{
      margin-bottom: 30px;
    }

    button{
      cursor: pointer;
      background-color: transparent;
      font-family: 'Poppins', sans-serif;
      padding: 5px;
      border: 1px solid $color1;
      color: $color1;
      margin: auto 12px;
      width: 30%;
    }
  }
</style>
