import { morning, standard } from './pricing';

const calculatePricing = (start, end, date) => {
  const day = new Date(date).getDay();
  const isWeekend = day === 0 || day === 6;

  if (isWeekend) {
    if (morning[end - start]) {
      return morning[end - start];
    }
    return null;
  }

  if (start > 30 && start < 58) {
    if (standard[end - start]) {
      return standard[end - start];
    }
    return null;
  }

  if (morning[end - start]) {
    return morning[end - start];
  }
  return null;
};

export default calculatePricing;
