<template>
  <div class="cart">
    <h4>Izvēlētie laiki:</h4>
    <div
      v-for="(item, index) of $store.getters.cart"
      :key="index"
      class="cart__item"
    >
      <div class="cart__item__desc">
        <span class="cart__item__desc__date">
          {{ item.date.replace(/^(\d{4})-(\d{2})-(\d{2})$/, '$3.$2.$1') }}
        </span>
        <span class="cart__item__desc__time">
          {{ timeSlots[item.start_index] + " - " + timeSlots[item.end_index] }}
        </span>
      </div>
      <div class="cart__item__price">
        <span>&euro;{{ parseFloat(getTotal[index]).toFixed(2) }}</span>
        <span
          class="remove"
          @click="$store.dispatch('removeFromCart', index)"
        >Noņemt</span>
      </div>
    </div>
    <div class="total">
      <span>Kopā:</span>
      <span>&euro;{{ getTotal.reduce((total, current) => total + current, 0).toFixed(2) }}</span>
    </div>
    <div
      v-if="errorMessage.length"
      class="error"
    >
      <p>{{ errorMessage }}</p>
      <p>{{ conflictingTimeSlots }}</p>
    </div>
    <div
      v-else
      class="terms"
    >
      <p>
        Apmaksājot, Jūs piekrītat
        <router-link to="/terms">
          iekšējās kārtības un lietošanas noteikumiem
        </router-link>
      </p>
    </div>
    <button
      class="btn"
      :disabled="checkoutLoading"
      @click="pay()"
    >
      <loading-bar v-if="checkoutLoading" />
      <span v-else>Apmaksāt</span>
    </button>
  </div>
</template>
<script>
import LoadingBar from './LoadingBar.vue';
import calculatePricing from '../utilities/calculatePricing';

export default {
  name: 'CartComponent',
  components: { LoadingBar },
  data() {
    return {
      timeSlots: {
        0: '06:30',
        1: '06:45',
        2: '07:00',
        3: '07:15',
        4: '07:30',
        5: '07:45',
        6: '08:00',
        7: '08:15',
        8: '08:30',
        9: '08:45',
        10: '09:00',
        11: '09:15',
        12: '09:30',
        13: '09:45',
        14: '10:00',
        15: '10:15',
        16: '10:30',
        17: '10:45',
        18: '11:00',
        19: '11:15',
        20: '11:30',
        21: '11:45',
        22: '12:00',
        23: '12:15',
        24: '12:30',
        25: '12:45',
        26: '13:00',
        27: '13:15',
        28: '13:30',
        29: '13:45',
        30: '14:00',
        31: '14:15',
        32: '14:30',
        33: '14:45',
        34: '15:00',
        35: '15:15',
        36: '15:30',
        37: '15:45',
        38: '16:00',
        39: '16:15',
        40: '16:30',
        41: '16:45',
        42: '17:00',
        43: '17:15',
        44: '17:30',
        45: '17:45',
        46: '18:00',
        47: '18:15',
        48: '18:30',
        49: '18:45',
        50: '19:00',
        51: '19:15',
        52: '19:30',
        53: '19:45',
        54: '20:00',
        55: '20:15',
        56: '20:30',
        57: '20:45',
        58: '21:00',
        59: '21:15',
        60: '21:30',
        61: '21:45',
        62: '22:00',
        63: '22:15',
        64: '22:30',
        65: '22:45',
        66: '23:00',
        67: '23:15',
        68: '23:30',
        69: '23:45',
        70: '00:00',
      },
      pricing: {
        3: 7.50,
        4: 10.00,
        5: 12.50,
        6: 15.00,
      },
      pricingCheap: {
        3: 3.75,
        4: 5.00,
        5: 6.25,
        6: 7.50,
      },
      dayDict: {
        Monday: 'Pirmdien',
        Tuesday: 'Otrdien',
        Wednesday: 'Trešdien',
        Thursday: 'Ceturtdien',
        Friday: 'Piektdien',
        Saturday: 'Sestdien',
        Sunday: 'Svētdien',
      },
      checkoutLoading: false,
      conflictingTimeSlots: '',
      errorMessage: '',
    };
  },
  computed: {
    getTotal() {
      const total = [];

      this.$store.getters.cart.forEach((appointment) => {
        const price = calculatePricing(
          appointment.start_index,
          appointment.end_index,
          appointment.date,
        );
        total.push(price);
      });

      return total;
    },
  },
  methods: {
    pay() {
      this.checkoutLoading = true;
      this.errorMessage = '';
      this.conflictingTimeSlots = '';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.$store.getters.cart),
      };
      fetch(`${process.env.VUE_APP_HOST_URL}/api/checkout`, requestOptions)
        .then((response) => {
          if (response.ok) return response.json();
          if (response.status === 409) {
            return response.json().then((res) => {
              this.errorMessage = res.msg;
              this.conflictingTimeSlots = `Pārklājas ar ${res.conflicts.join(', ')}`;
            });
          }
          return response.json().then((json) => {
            Promise.reject(json);
            this.checkoutLoading = false;
          });
        })
        .then((data) => { window.location = data.url; })
        .catch((e) => {
          console.error(e.error);
          this.checkoutLoading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap');
    @import 'colors.scss';
    .cart{
        font-family: 'Poppins', sans-serif;
        color: $color1;
        font-size: 18px;
        background-color: $color4;
        padding: 10px 5px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        margin-bottom: 3px;
        margin-top: 10px;

        h4{
            font-size: 22px;
            margin: 5px;
            padding: 5px;
        }

        &__item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3px 10px;
            border-bottom: 1px solid $color1;

            &:nth-child(2){
                border-top: 1px solid $color1;
            }

            &__desc{
                display: flex;
                flex-direction: column;

                &__date{
                    font-weight: bold;
                }

                &__time{
                    font-size: 14px;
                }
            }

            &__price{
                display: flex;
                flex-direction: column;

                .remove{
                    font-size: 10px;
                    text-decoration: underline;
                    font-weight: bold;
                    cursor: pointer;
                    align-self: flex-end;
                }
            }
        }

        .total{
            display: flex;
            justify-content: space-between;
            padding: 3px 10px;
            margin-bottom: 20px;

            span{
                font-weight: bold;
            }
        }
    }

    .btn{
        font-size: 20px;
        margin-bottom: 24px;
        background-color: $color4;
        transition: background-color 1s ease;
        color: $color1;
        border: 1px solid $color1;
        padding: 10px;
        height: 52px;
        width: 300px;
        font-family: 'Poppins', sans-serif;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:disabled{
            cursor: unset;
            background-color: rgb(230, 230, 230);
        }
    }

    .error{
        padding: 15px;

        p:first-child{
            font-size: 14px;
            font-weight: 600;
            color: rgb(135, 26, 26);
        }

        p:last-child{
            font-size: 14px;
            color: rgb(135, 26, 26);
        }
    }

    .terms{
        padding: 10px;

        p{
            font-size: 14px;
            font-weight: 400;

            a{
              color: unset;
            }
        }
    }
</style>
